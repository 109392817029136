.btn-custom {
  background-color: #FFCC00; /* Replace with your extracted color */
  border-color: #FFCC00; /* Optional: To match border color */
  color: white; /* Text color */
}

.btn-custom:hover {
  background-color: #e6b800; /* Optional: Darker shade on hover */
  border-color: #e6b800; /* Optional: Darker border on hover */
}

body {
  background-color: #51545D;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}
